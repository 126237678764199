import axios from 'axios';
import config from './config';
import i18n from './i18n';

import {getAccessToken} from './tokens/tokenHelper';
import {
  FAKE_CLIENT_URL_QUOTA,
  FAKE_DOMAIN_RESPONSE,
  FAKE_CREATED_URL,
  FAKE_REMOVED_URL,
  FAKE_URL_SUGGESTED_QUERY,
  getFakeUrlResponse,
  getFakedValidatedUrls
} from './apiMocks/reviewsMockedData';

const apiRoot = config.BW_API_ROOT_URL;
const PATHS = {
  ME: '/me',
  CLIENT: '/client',
  CONTENT_SOURCES: '/content/sources',
  CONTENT_SOURCES_LIST: '/content/sources/list',
  CONTENT_UPLOAD: '/content/upload',
  PROJECTS: '/projects',
  PROJECTS_SUMMARY: '/projects/summary'
};

const createInstance = (token, options = {}) =>
  axios.create({
    baseURL: apiRoot,
    timeout: 0,
    headers: {
      Authorization: `bearer ${token}`,
      'Accept-Language': i18n.language
    },
    ...options
  });

export const getMe = async () => {
  const {data} = await createInstance(await getAccessToken()).get(PATHS.ME);
  return data;
};

export const getClient = async () => {
  const token = await getAccessToken();
  const {data} = await createInstance(token).get(PATHS.CLIENT);
  return data;
};

export const getProjects = async params => {
  const {data} = await createInstance(await getAccessToken()).get(
    PATHS.PROJECTS_SUMMARY,
    {params}
  );
  return data;
};

export const getProjectById = async projectId => {
  const {data} = await createInstance(await getAccessToken()).get(
    `${PATHS.PROJECTS}/${projectId}`
  );
  return data;
};

export const listContentSources = async () => {
  const {data} = await createInstance(await getAccessToken()).get(
    PATHS.CONTENT_SOURCES_LIST
  );
  return data.results;
};

export const createContentSource = async ({
  name,
  description = 'Custom Content Source',
  projectIds = []
}) => {
  const token = await getAccessToken();
  const {data} = await createInstance(token).post(PATHS.CONTENT_SOURCES, {
    name,
    description,
    projectIds
  });
  return data;
};

export const deleteContentSource = async contentSourceId =>
  await createInstance(await getAccessToken()).delete(
    `${PATHS.CONTENT_SOURCES}/${contentSourceId}`
  );

export const getContentSource = async contentSourceId =>
  await createInstance(await getAccessToken()).get(
    `${PATHS.CONTENT_SOURCES}/${contentSourceId}`
  );

export const listFacebookTerms = async params => {
  const {data} = await createInstance(await getAccessToken()).get(
    `/facebook/trackedpages`,
    {
      params
    }
  );
  return data;
};

export const deleteTrackedFacebookPage = async facebookTerm => {
  const {pageId, owned} = facebookTerm;
  return await createInstance(await getAccessToken()).patch(
    `/facebook/trackedpages/${pageId}`,
    {pageId, owned, enabled: false}
  );
};

export const uploadData = async ({contentSourceId, items}) => {
  try {
    const {data} = await createInstance(await getAccessToken(), {
      timeout: 60000
    }).post(PATHS.CONTENT_UPLOAD, {
      items,
      contentSource: contentSourceId,
      requestUsage: true
    });

    return data;
  } catch (error) {
    if (error.response) {
      const {status, data = {}} = error.response;

      if (status === 400) {
        return {errors: data.errors};
      }

      if (status === 429) {
        throw new Error(
          'You have exceeded your upload limit. Contact Support.'
        );
      }
    }

    console.error('upload failed');
    console.error(error);
    throw error;
  }
};

//for single tracking flow
export const trackFacebookNonownedPages = async pageId => {
  const {data} = await createInstance(await getAccessToken()).post(
    '/facebook/trackedpages',
    [{enabled: true, pageId, owned: false}]
  );
  return data;
};

//for bulk upload flow
export const trackNonownedFacebookPages = async pageIds => {
  const {data} = await createInstance(await getAccessToken()).post(
    '/facebook/trackedpages',
    pageIds.map(pageId => ({enabled: true, pageId, owned: false}))
  );
  return data;
};

export const checkForFacebookAccess = async () => {
  const {data} = await createInstance(await getAccessToken()).get(
    '/facebook/authentications/users',
    {
      params: {authenticated: true}
    }
  );
  return data;
};

//for csv export and download
function createDownload(data, fileName) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const exportFacebookCsv = async params => {
  const response = await createInstance(await getAccessToken()).get(
    '/facebook/trackedpages.csv',
    {
      params: {...params},
      responseType: 'blob'
    }
  );
  createDownload(response.data, 'FacebookPages.csv');
};

export const exportInstagramAccountsCsv = async params => {
  const response = await createInstance(await getAccessToken()).get(
    '/instagram/v2/trackedaccounts.csv',
    {
      params: {...params},
      responseType: 'blob'
    }
  );
  createDownload(response.data, 'InstagramAccounts.csv');
};

export const exportInstagramHashtagsCsv = async params => {
  const response = await createInstance(await getAccessToken()).get(
    '/instagram/v2/hashtags.csv',
    {
      params: {...params},
      responseType: 'blob'
    }
  );
  createDownload(response.data, 'InstagramHashtags.csv');
};

export const exportWhosConnectedCsv = async params => {
  const response = await createInstance(await getAccessToken()).get(
    '/instagram/v2/authentications/users.csv',
    {
      params: {...params},
      responseType: 'blob'
    }
  );
  createDownload(response.data, 'WhosConnected.csv');
};

export const deleteFacebookUser = async fbUserId => {
  const {data} = await createInstance(await getAccessToken()).delete(
    '/instagram/v2/authentications/users',
    {data: [fbUserId]}
  );
  return data;
};

export const getReviewsDomains = async (filterString, page) => {
  const params = {
    search: filterString,
    page
  };

  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = FAKE_DOMAIN_RESPONSE;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).get(
    `/reviews/domains`,
    {params}
  );
  return data;
};

export const getReviewsUrls = async (filterString, page) => {
  const params = {
    search: filterString,
    page
  };

  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = getFakeUrlResponse(page);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).get(
    `/reviews/urls`,
    {params}
  );
  return data;
};

export const getReviewsClientQuota = async () => {
  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = FAKE_CLIENT_URL_QUOTA;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).get(
    `/reviews/urls`
  );
  return data;
};

export const validateReviewsUrls = async urlRequest => {
  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = getFakedValidatedUrls(urlRequest.urls);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).post(
    '/reviews/urls/validate',
    urlRequest
  );

  return data;
};

export const createReviewsUrls = async (urls, bcrProjectId) => {
  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = FAKE_CREATED_URL;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).post(
    '/reviews/urls',
    {
      urls,
      bcrProjectId
    }
  );

  return data;
};

export const deleteReviewsUrls = async ({urlIds, filters}) => {
  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = FAKE_REMOVED_URL;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).post(
    '/reviews/urls/delete',
    {
      urlIds,
      filters
    }
  );

  return data;
};

export const getReviewsUrlsSuggestedBCRQueryString = async urlFilter => {
  if (config.USE_TEMPORAL_REVIEWS_MOCKED_DATA) {
    const {data} = FAKE_URL_SUGGESTED_QUERY;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data.suggestedQuery);
      }, 2000);
    });
  }

  const {data} = await createInstance(await getAccessToken()).post(
    `/reviews/urls/bcr/suggested-query`,
    urlFilter
  );

  return data.suggestedQuery;
};

export const createBCRBooleanQuery = async (query, bcrProjectId) => {
  const {data} = await createInstance(await getAccessToken()).post(
    `/projects/${bcrProjectId}/queries`,
    query
  );

  return data;
};
